/* Provide sufficient contrast against white background */
:root {
    --primary-color: #66cc33;
}

@font-face {
    font-family: 'Oswald';
    src: local('Oswald'), url(./fonts/Oswald-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans'), url(./fonts/fira-sans.regular.ttf) format('truetype');
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
    color: black;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    border-radius: 0;
    font-family: Oswald;
    font-weight: bold;
    padding: 10px 15px;
}
.btn-primary:hover {
    color: var(--primary-color);
    background-color: black;
    border-color: #66cc33;
}
.btn-secondary {
    color: white;
    background-color: black;
    border-color: black;
    border-radius: 0;
    font-family: Oswald;
    font-weight: 500;
    padding: 10px 15px;
}
.btn-tertiary {
    color: black;
    background-color: white;
    border-color: black;
    border-radius: 0;
    font-family: Oswald;
    font-weight: 500;
    padding: 10px 15px;
}
.btn-tertiary:hover {
    border-color: black;
}
.btn-danger {
    font-family: Oswald;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 0;
}
.form-check-input[type=radio] {
    height: 20px;
    width: 20px;
}
.form-check-input:checked {
    background-color: var(--primary-color);
    border-color: black;
}
.form-check-input:checked[type=radio] {
/*    background-image: none;
    box-shadow: none;*/
}
.form-check-input:checked[type=checkbox] {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}
input {
    touch-action: none;
}
img.scale-cat {
    display: block;
    max-width: 100%;
    width: 100% !important;
    min-height: 230px !important;
    cursor: pointer;
    width: auto;
    -ms-interpolation-mode: bicubic;
    bottom: 0;
    object-fit: contain;
    max-height: 230px;
    padding: 0px;
    margin-top: 15px;
    margin-bottom: auto;
}
.center-block {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
}
.catalog-box {
    border: 1px solid #404B56;
    border: 1px solid #BDBDBE;
    box-shadow: 0px 6px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 5px;
    margin-top: 5px;
    height: 360px;
    background: #ffffff;
    transition: 500ms;
}
.catalog-nameheader {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-size: 18px;
    letter-spacing: 0px;
    color: #1B1B1B;
    opacity: 1;
    padding: 12px;
    position: absolute;
    z-index: 1;
    line-height: 23px;
    bottom: 0px;
    width: 92%;
}
.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
.attribute-button {
    color: #404B56;
}
.attribute-item h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-transform: initial;
    margin-bottom: 3px;
}

.attribute-item p {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

body {
    font-family: Fira Sans;
    background-color: #f1f1f1;
}

nav {
    margin-bottom: 0 !important;
    background-color: white;
    border-bottom: 5px solid;
    border-color: var(--primary-color)
}

#root > div > div {
    background-color: #f1f1f1;
    max-width: none;
}

/*CHECKOUT PAGE*/

.main-container {
    padding-top: 20px;
}

.heading {
    font-family: Oswald;
    font-weight: bold;
}

.card-main-title {
    font-size: 35px;
    margin-top: 5px;
    margin-left: 15px;
}

/*.active-heading {
    margin-bottom: 20px;
}*/

hr {
    /*    border-top: var(--bs-border-width) solid #66cc33;*/
    border: none;
    height: 1px;
    color: #66cc33;
    background-color: #66cc33;
    opacity: 1;
}

.green {
    fill: #66cc33;
}

.bottom-hr {
    margin-top: 4%;
}

.hr-left {
    padding-left: 0;
}

.bottom-hr-summary {
    margin-top: 11%;
}

.card {
    border: none;
}

.card-body {
    margin-left: 15px;
    margin-right: 15px;
}

.shipping-card {
    margin-bottom: 40px;
}

.summary-card {
    margin-bottom: 40px;
    background-color: #e5e5e9;
}

.vertical-line {
    border-left: thick solid #66cc33;
}

.smaller-text {
    color: darkgray;
    font-weight: normal;
    font-size: 20px;
    margin-right: 5px;
}

.lighter-text {
    font-size: smaller;
    color: gray;
}

.radio-ship {
    margin-bottom: 15px;
}

.shipping-row {
    margin-bottom: 15px;
}

.radio-ship label {
    margin-top: 3px;
    margin-left: 4px;
}

.radio-right .radio-ship input, .radio-left .radio-ship input {
    float: unset;
    margin-right: 5px;
}

.radio-right {
    text-align: right;
}

.radio-left {
    text-align: left;
}

.ship-review label{
    font-size: large;
    margin-left: 4px;
}

.right-text {
    float: right;
}

.right {
    float: right;
}

.spacing-left {
    margin-left: 1%;
}

input {
    border-color: black !important;
}

select {
    border-color: black !important;
}

.form-floating {
    margin-top: 2%;
    margin-bottom: 2%;
    height: 50%;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
 /*   padding: 0 !important;*/
}

.checkbox {
    font-size: smaller;
}

.checkbox input {
    font-size: large;
}

.checkbox label {
    margin-top: 3px;
}

.email-checkbox {
    margin-top: 10px;
}

.title-group-active {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
}

.title-group-inactive {
    background-color: black;
    color: white;
    width: 100%;
    margin: 0 auto;
}

#ship-my-address .heading {
    margin-top: 3%;
}

.inactive-body {
    display: none;
}

.clickable {
    cursor: pointer;
}

.inactive-form {
    display: none;
}

.dealer-div-holder {
    margin: 0 auto;
    background-color: #f1f1f1;
}

.dealer-ship-box {
    box-shadow: 0px 6px 16px #00000029;
    padding: 3%;
    margin-left: 3%;
    margin-right: 3%;
}

.dealer-ship-holder {
    background-color: #f1f1f1;
    height: 175px;
    overflow: auto;
    margin-left: 0;
}

.dealer-ship-holder .radio-ship {
    margin-bottom: 0;
}

.dealer-ship-holder label {
    font-size: 14px;
    font-family: Oswald;
    font-weight: 600;
}

.dealer-ship-holder p {
    font-size: 14px;
    margin-left: 11%;
    margin-top: 0;
}

.dealer-heading {
    font-family: Oswald;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
}

.dealer-results .dealer-heading {
    text-align: center;
    text-transform: uppercase;
}

.dealer-div-holder .radio-ship {
    margin-bottom: 0;
    margin-top: 3%;
}

.dealer-info {
    margin-left: 12%;
    font-size: 0.8rem;
    margin-bottom: 1rem;
}

.dealer-info p {
    margin-bottom: 0;
}

.dealer-continue {
    margin-top: 5%;
}

.edit-address {
    float: right;
    text-decoration: underline;
}

.edit-button {
    font-size: initial;
    color: black;
}

.underline {
    text-decoration: underline;
    cursor: pointer;
}

.bold {
    font-weight: bold;
}

.change-shipping {
    margin-top: 3%;
}

.shipping-text {
    margin-left: 1%;
}

.review-shipping p {
    margin-bottom: 0;
}

.payment-heading {
    margin-bottom: 15px;
}

.payment-type label {
 /*   margin-top: 3px;*/
    margin-left: 3px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.indent-left {
 /*   margin-left: 3%;*/
    padding-left: 1%;
}

svg {
    width: 1em;
    height: 1em;
    font-size: 9px;
    margin-right: 3px;
    margin-bottom: 4px;
}

.active-svg {
    display: initial;
}

.inactive-svg {
    display: none;
}

.items-list {
    cursor: pointer;
}

.items-list-second {
    cursor: pointer;
}

.item-holder {
    margin-top: 5%;
}

.item-image {
    border: 1px solid black;
   /* height: 100%;*/
    width: 100%;
}

.item-title {
    font-family: Oswald;
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 5% !important;
}

.item-holder p {
    margin-bottom: 0;
}

.item-desc {
    font-size: 0.8rem;
}

.help-texts {
    margin-left: 2%;
}

.help-text {
    font-size: 0.8rem;
    font-weight: 600;
}

.need-help {
    cursor: pointer;
}

.hide-items {
    display: none;
}

.continue {
    padding-left: 25px;
    padding-right: 30px;
    margin-bottom: 30px;
}

.submit {
    padding: 10px 15px;
    margin-bottom: 20px;
}

.what-text {
    margin-bottom: 0;
}

.signin-card {
    margin-bottom: 10px;
    background-color: #e5e5e9;
}

.signin-text {
    align-items: center;
    display: flex;
}

.signin-text p {
    margin-bottom: 0;
}

.signin-btn {
    text-align: right;
}

.signin-btn button {
    white-space: nowrap;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 1%;
}

.uppercase {
    text-transform: uppercase; 
}

.billing-row {
    border-bottom: 1px solid lightgray;
    margin-bottom: 2%;
}

.payment-row {
    border-top: 1px solid lightgray;
}

.payment-row-bottom {
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    margin-bottom: 3%;
}

.payment-row > div {
    margin-top: 2%;
}

.payment-right {
    margin-left: 2%;
}

.red {
    color: red;
}

.debit-info {
    font-weight: 400;
}

.select-drop p {
    margin-bottom: 0;
    text-align: right;
}

.dealer-search {
    /*padding-right: 0;*/
}

.dealer-ship-info {
    border: 2px solid #66cc33;
}

.dealer-ship-info p {
    margin: 0 !important;
}

.shipping-to {
    font-size: 0.8rem;
    color: #666666;
}

.dealer-ship-info .col-sm-3 {
    text-align: center;
}

.dealer-ship-info button {
    font-size: 0.9rem;
    padding: 6px 15px;
    min-width: 66.8px;
}

.search-btn {
    border-radius: 12px;
    height: 58px;
    width: 58px;
    justify-content: center;
    align-items: center;
    display: inline-block;
    margin-top: 1%;
}

.search-btn svg {
    font-size: 18px;
    fill: white;
}

.address-modal-row {
    width: 60%;
    margin: 0 auto;
    margin-left: 30%;
}

.address-modal-row > div {
    width: unset;
}

.tooltips .what-text .tooltiptext {
    left: 0;
    width: 250px;
    top: 120%;
}

.tooltiptext img {
    width: 100%;
}

.checkout-title-row {
    margin-bottom: 2%;
    margin-top: 1%;
}

.checkout-title *, .checkout-title {
    max-width: fit-content;
}

.checkout-title * {
    margin-bottom: 0;
    margin-top: 0;
    white-space: nowrap;
}

.payment-icon {
    width: 40px;
    height: 100%;
    margin-right: 5px;
}

.paypal {
    border: 1px solid black;
    border-radius: 4px;
}

.google, .apple, .paypal {
    height: 27px;
    width: unset;
}

.checkout-title-under {
    border-bottom: 1px solid #66cc33;
}

.checkout-captcha {
    justify-content: end;
    display: flex;
}

/*CONFIRMATION PAGE*/
.confirmation-card {
    margin-top: 40px;
    margin-bottom: 40px;
}

.confirmation-heading {
    width: fit-content;
    margin: 0 auto;
    margin-top: 2%;
    margin-bottom: 5%;
}

.confirm-preferred {
    border-bottom: 5px solid #66cc33;
}

.confirm-preferred p {
    margin-left: 15px;
}

.confirm-title {
    font-size: 45px;
    margin-left: 0;
    text-align: center;
}

.preferred-title {
    margin-top: 2%;
}

.confirm-main-row {
  /*  height: 70vh;*/
}

.confirm-text {
    text-align: center;
}

.first-confirm p {
    margin-bottom: 0;
}

.confirmation-text {
    font-weight: 700;
    font-size: 20px;
}

.confirm-code {
    font-family: Oswald;
    font-weight: 600;
}

.order-number, .link-decoration {
    text-decoration: underline;
    font-weight: 900;
    font-family: Oswald;
}

.order-number {
    color: #66cc33;
    cursor: pointer;
}

.confirm-button {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 10%;
}

.first-button {
    margin-bottom: 2%;
}

.first-confirm {
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 4%;
    padding: 2%;
}

.second-confirm {
    margin-bottom: 7%
}

.error-msg {
    color: red;
    font-family: Fira Sans;
    text-align: center;
}

.loader-div .modal-content {
    background: transparent;
    border: 0;
}

.modal-content span {
    justify-content: center;
    display: flex;
}

.preferred-row {
    background-color: #d7d7da;
    margin-left: 0;
    margin-right: 0;
}

.confirm-second-col {
/*    background-color: #d7d7da;
    padding-left: 0;
    padding-right: 0;*/
}

.preferred-col {
  /*  height: 100%;*/
}

.preferred-form {
    margin-top: 5%;
    height: unset;
}

.dealer-locations {
    border-radius: 7px;
    overflow-y: auto;
    max-height: 65%;
    background-color: #f1f1f1;
    box-shadow: 0px 6px 6px #00000029;
    position: absolute;
    width: 23%;
}

.dealer-locations .dealer-heading, .dealer-locations-short .dealer-heading {
    text-transform: uppercase;
    margin-bottom: 0;
    margin-left: 5%;
    margin-top: 3%;
}

.product-lines {
    font-style: italic;
    font-weight: 600;
}

.dealer-locations .dealer-info, .dealer-locations-short .dealer-info {
    margin-left: 15%;
}

h5 {
    font-family: Oswald;
    font-weight: 600;
}

.review-heading {
    margin-bottom: 5%;
}

.review-last-heading {
    margin-top: 3%;
}

.dealer-locations input[type="radio"]:checked + label, .dealer-locations-short input[type="radio"]:checked + label {
    color: #66cc33;
}

.dealer-saved {
    display: none;
    font-family: 'Fira Sans';
    text-transform: none;
    font-style: italic;
    padding-left: 1%;
    font-size: 0.8rem;
}

.dealer-saved svg {
    margin-right: 0;
    width: 2em;
    height: 2em;
}

.dealer-locations input[type="radio"]:checked + label .dealer-saved {
    display: block;
}

.confirm-dealers-search {
    margin-top: 4%;
}

.confirm-btn-title {
    padding: 0;
}

.confirm-btn-title button {
    white-space: nowrap;
    margin-top: 4%;
    margin-right: 5%;
}

.dealer-locations-short {
    border-radius: 7px;
    background-color: #f1f1f1;
    box-shadow: 0px 6px 6px #00000029;
}

.input-search {
    position: relative;
    display: inline-flex;
}

.input-search button {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    margin-top: 0;
    padding: 3px;
}

.input-search svg {
    fill: white;
    font-size: 17px;
}

input.search-box {
    width: 250px;
    font-family: FontAwesome;
    padding: 2px 0;
}

.confirm-map {
    margin-top: 5%;
    margin-bottom: 3%;
    width: 100%;
}

.confirm-zip-btn {
    max-width: fit-content;
    margin-top: 3%;
}

.checkout-summary {
    margin-bottom: 5%;
}

div.confirm-page-footer {
    margin-top: 20%;
}

/*  MODAL CONTENT */

.modal-content {
    text-align: center;
}

.modal-header {
    font-family: Oswald;
    font-size: 1.5rem;
    color: white;
    background-color: black;
    border-bottom: 5px solid #66cc33;
    text-align: center;
}

.modal-header .h4 {
    font-size: 2rem !important;
    font-weight: 600;
}

.modal-header .btn-close {
    position: absolute;
    right: 5%;
}

.modal-footer {
    border-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-footer-buttons {
    width: 100%;
}

/* HELP CENTER */

.help-center-title {
    max-width: fit-content;
}

.help-under-title {
    margin-left: 15px;
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: 600;
}

.home-help-container .help-card {
    background: #f1f1f1 0% 0% no-repeat padding-box;
    box-shadow: none;
}

.help-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 6px #00000029;
    border-radius: 12px;
    margin-bottom: 50px;
    margin-top: 3%;
}

.help-card-inner {
    background-color: white;
}

.help-card .row {
    margin-left: 0;
    margin-right: 0;
}

.help-title {
    margin-top: 3%;
    margin-bottom: 5%;
}

.detail-well {
    box-shadow: 0px 3px 3px 0px #00000029;
    border-radius: 12px;
}

.help-card .card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px 2px #00000029;
    border-radius: 12px;
    margin-bottom: 50px;
    width: 101%;
}

.card-img-top {
    border-bottom: 5px solid;
    border-color: #66cc33;
}

.svg-holder {
    height: 100%;
    text-align: center;
}

.svg-holder svg {
    height: 9em;
    width: unset;
    margin-top: 3%;
    margin-bottom: 2%;
}

.st5 {
    display: inline;
    fill: #6FBE44;
}

.st6 {
    display: inline;
    fill: #FFFFFF;
}

.st7, .st3 {
    fill: #6FBE44;
}

.st8 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}

.help-center-card-title {
    text-align: center;
    font-family: Oswald;
    font-weight: 600;
    margin-bottom: 0;
}

.help-order-text {
    width: 80%;
    text-align: center;
    margin: 0 auto !important;
}

.help-order-input {
    width: 70%;
    margin: 0 auto  !important;
}

.help-card .form-floating > label {
    left: 12px;
}

.help-order-text-help {
    width: 70%;
    margin: 0 auto !important;
    text-align: right;
    justify-content: end;
}

.help-order-text-help p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 12px;
    width: fit-content;
}

.help-order-divider {
    width: 80%;
    margin: 0 auto !important;
}

.help-order-divider hr {
    margin-top: 3%;
    margin-bottom: 5%;
}

.help-btn {
    width: fit-content;
    margin: 0 auto !important;
}

.help-btn button {
    margin-top: 5%;
    margin-bottom: 7%;
}

.st21 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #71BF44;
}

.st22 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #FFFFFF;
}

.st23 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #FFFFFF;
    stroke: #72BF44;
    stroke-miterlimit: 10;
}

.tooltips {
    position: relative;
}

.tooltips .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: #f1f1f1;
    color: black;
    border: 1px solid black;
    text-align: center;
    border-radius: 6px;
    padding: 8px 5px;
    position: absolute;
    z-index: 3;
    top: 135%;
    left: 72%;
    margin-left: -60px;
}

.tooltips .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.tooltips:hover .tooltiptext {
    visibility: visible;
}

.help-title-row {
    margin-left: 0;
    margin-right: 0;
}

.help-container {
 /*   padding-bottom: 2%;*/
}

.help-text-card {
    margin: 0;
}

.faq-all {
    text-align: center;
    font-weight: 600;
    font-size: 1.0rem;
    margin-bottom: 20px;
}

.holiday-banner {
    background-color: #66cc33;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    padding-top: 10px;
    padding-bottom: 15px;
}

@media (min-width: 1880px) {
    .holiday-banner {
        max-width: 101.3%;
    }
}

.holiday-banner p {
    color: white;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 0;
}

/* ORDER ITEM PAGE */

.order-item-title {
    background-color: black;
    padding: 3%;
}

.order-item-title * {
    color: white;
}

.order-item-title h3 {
    font-size: 23px;
}

.order-back {
    white-space: nowrap;
    font-weight: 600;
}

.order-item-title .card-main-title {
    margin-left: 0;
}

.order-item-title p {
    text-align: right;
}

.order-ship-info {
    margin-top: 4%;
}

.order-text-info {
    width: 90%;
    margin-left: 5% !important;
}

.order-ship-info .row {
    margin-bottom: 5%;
}

.order-ship-info p {
    margin-bottom: 0;
}

.order-subdivison-head {
    background-color: black;
    padding: 3% 0;
}

.order-title-text {
    color: white !important;
    text-align: center;
}

.green-under {
    color: #66cc33;
    text-decoration: underline;
}

.order-table {
    width: 89%;
    margin: 4% auto !important;
}

.order-table th {
    background-color: black;
    color: white;
    text-align: center;
}

.order-table td {
    text-align: center;
    vertical-align: middle;
    padding: 1rem 1rem;
}

.order-table .table-left {
    text-align: left;
    min-width: 230px;
    max-width: 230px;
    width: 230px;
}

.table-img {
    min-width: 110px;
    max-width: 110px;
}

.order-page .table-img {
    width: 110px;
    min-width: 110px;
}

.price-table tr, .price-table td {
    border: none;
    text-align: left;
}

.price-table td {
    padding: 0.5rem 0.5rem;
}

.first-price-row td {
    padding-top: 4%;
}

.total-table td {
    font-family: Oswald;
    font-weight: 600;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.order-item-logo {
    width: 200px;
    margin-bottom: 3%;
}

.order-item-footer {
    text-align: center;
    font-weight: 600;
}

.order-bottom {
    margin-top: 2%;
    margin-bottom: 2%;
}

.order-item-thanks {
    text-align: center;
    color: gray;
    font-size: 0.9rem;
}

.help-card .return-info-lookup {
    margin-left: 6%;
}

.return-info-lookup h5 {
    font-size: 2rem;
}

.return-info-details p {
    margin-bottom: 10px;
}

.return-print {
    margin-top: 15%;
}

.return-print p {
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
}

.cancel-btn {
    margin-bottom: 5%;
}

.order-text-info .order-refund-bottom {
    margin-bottom: 7%;
}

/* RETURN DETAILS */

.return-title {
    text-align: right;
    padding-left: 8px;
}

.return-title-row {
    margin-top: 2%;
}

.return-title-col {
    display: flex;
}

.return-text {
    text-align: center;
}

.return-rows:hover {
    cursor: pointer;
}

.return-quantity p {
    width: fit-content;
    margin: 0 auto;
    padding: 8% 25%;
    border: 1px solid black;
    border-radius: 8px;
}

.return-quantity {
    width: 125px;
    min-width: 125px;
}

.return-quantity input {
    text-align: center;
}

.return-reason {
    min-width: 200px;
}

.return-comments {
    min-width: 225px;
}

.return-btn {
    margin-bottom: 30px;
}

.order-table textarea {
    border: 1px solid black;
}

.disabled p {
    background-color: #e9ecef;
}

.return-submit-btn-col {
    text-align: center;
}

.return details .contact-title {
    margin-left: 20%;
}

/*.mobile-only select option {
    width: 100px;
}*/


@media (max-width: 767px) {
    .non-mobile {
        display: none;
    }
}

@media (min-width: 768px) {
    .mobile-only {
        display: none;
    }
}

/* RETURN CONFIRM */

.return-confirm-page-btn {
    margin-bottom: 3%;
}

.return-confirm-footer {
    margin-top: 9%;
}

/* CONTACT US */

.contact-form {
    margin-top: 4%;
}

.contact-title {
    white-space: nowrap;
    margin-left: 25%;
}

.contact-form label {
    font-weight: 600;
}

.contact-form textarea {
    border: 1px solid black;
}

.contact-us-btn {
    text-align: center;
}

.captcha {
    margin-bottom: 2%;
}

/* CONTACT CONFIRM */

.ticket-number {
    text-decoration: underline;
}

.contact-text {
    width: 65%;
    margin: 0 auto 3%;
}

/* FAQs */

.faq-heading {
    margin-bottom: 2%;
}

.faqs-title {
    padding-left: 8px;
    margin-left: 0;
}

.faq-tabs-col {
    padding-right: 0;
}

.faq-tabs {
    margin-top: 5%;
}

.faq-tabs .list-group-item {
    font-family: Oswald;
    font-weight: 600;
    font-size: 20px;
    border-left: 5px solid darkgray;
    color: gray;
    box-shadow: 0px 0px 5px 3px #00000029;
}

.faq-svg {
    height: 35px;
    width: 52.27px;
    display: inline-block;
    margin-right: 10%;
}

.faq-svg svg {
    height: 100%;
    width: 100%;
}

.faq-svg .st3, .faq-svg .st5, .faq-svg .st21, .faq-svg .st23, .faq-svg .st7 {
    fill: gray;
}

.faq-tabs .faq-active-tab {
    color: #66cc33;
    border-left: 5px solid #66cc33;
}

.faq-active-tab .faq-svg .st3, .faq-active-tab .faq-svg .st5, .faq-active-tab .faq-svg .st21, .faq-active-tab .faq-svg .st23, .faq-active-tab .faq-svg .st7 {
    fill: #66cc33;
}

.main-tab-group .green {
    padding-left: 3%;
    color: #66cc33;
    margin-top: 2%;
}

.faq-row {
    margin-top: 3%;
    margin-bottom: 1%;
}

.faq-title {
    max-width: fit-content;
    padding-right: 0;
    padding-left: 3%;
}

.faq-title h5 {
    margin-bottom: 0;
    cursor: pointer;
    white-space: nowrap;
}

.faq-long-col p {
    margin-bottom: 0;
}

.faq-long-col {
    border-bottom: 1px solid #66cc33;
    margin-right: 6%;
}

.faq-description p {
    margin-left: 2%;
    width: 90%;
}

.faq-description ul {
    margin-left: 4%;
    width: 88%;
}

.faq-description ol {
    margin-left: 4%;
    width: 88%;
}

.faq-description .faq-left {
    margin-left: 4%;
}

.faq-body a {
    color: black;
}

.faq-description table {
    width: 35%;
    margin: auto;
    text-align: center;
}

.faq-bottom {
    margin-top: 5%;
    margin-bottom: 2%;
}

.faq-description .payment-icon {
    margin-right: 0;
}

/* FOOTER */
.footer {
    color: white;
    background-color: #0F0F10;
    padding-bottom: 20px;
}

.footer-privacy {
    color: white;
    background-color: #000000;
    padding-bottom: 20px;
    padding-top: 10px;
    padding-left: 3%;
}

.footer-privacy a {
    color: #fff;
    padding-right: 20px;
}

.footer-first {
    padding-left: 4%;
}

.footer-title {
    margin-top: 5%;
    border-bottom: 1px solid white;
}

.footer h4 {
    font-family: Oswald;
    padding-left: 0;
}

.footer p {
    padding-left: 0;
}

.footer-title > div {
    padding-left: 0;
    padding-right: 0;
}

.footer-title h4 {
    padding-top: 5px;
}

.footer-title p {
    text-align: center;
    height: 40px;
    padding-top: 5px;
}

.footer-title button {
    margin-bottom: 10px;
}

/*.footer-text {
    width: 55%;
}*/

.footer-top-text {
    margin-top: 5%;
}

.contact-us {
    width: fit-content;
}

.footer-heading-top {
    text-align: center;
    margin-top: 1%;
}

.phone-link {
    text-decoration: none;
    color: white;
}

.footer-customer {
    margin-bottom: 0;
}

.footer-customer-end {
    margin-bottom: 10%;
}

/* MEDIA QUERIES */

@media (min-width: 1600px) {
    .help-container {
        min-height: calc(82vh - 213px);
    }
}

@media (max-width: 1399px) and (min-width: 1200px) {
    .dealer-ship-info .col-lg-8 {
        width: 64%;
    }
    .dealer-ship-info .col-lg-4 {
        width: 36%;
    }
}

@media (max-width: 1399px) {
    .bottom-hr-summary {
        margin-top: 27%;
    }
    .tooltips .tooltiptext {
        left: 60%;
    }
    #googleMap > div {
        height: 580px !important;
    }
}

@media (max-width: 1199px) {
  /*  .confirm-button {
        width: 27%;
    }*/
    .footer-text {
        width: 100%;
    }
    .confirm-zip-search .mb-3 {
        margin-bottom: 0.5rem !important;
    }
    .confirm-zip-btn {
        margin-top: 0;
        margin-bottom: 1rem;
    }
    .confirm-btn-title button {
        float: unset;
        margin-left: 15%;
    }
    .footer-title p {
        text-align: left;
    }
    .contact-text {
        width: 80%;
    }
    #googleMap > div {
        height: 730px !important;
    }
    .dealer-locations {
        position: unset;
        width: unset;
    }
    .footer-phone br {
        display: none;
    }
}

@media (max-width: 1016px) {
    .footer-phone br {
        display: unset;
    }
}

@media (max-width: 991px) {
/*    .confirm-button {
        width: 38%
    }*/
    .modal-header .btn-close {
        right: 3%;
        top: 3%;
    }
    .signin-btn {
        text-align: center;
        margin-top: 2%;
    }
    .confirm-btn-title button {
        margin-right: 0;
        float: unset;
        margin-left: 10%;
    }
    .address-modal-row {
        width: 100%;
        margin: 0 auto;
        margin-left: 15%;
    }
    .tooltips .tooltiptext {
        left: 50%;
    }
    .confirm-zip-btn {
        margin-top: 3%;
        margin-bottom: 0;
    }
    .confirm-zip-search .mb-3 {
        margin-bottom: 1rem !important;
    }
    #googleMap > div {
        height: 533px !important;
    }
    .dealer-ship-holder label {
        display: unset;
    }
}

@media (max-width: 768px) {
    .hr-left {
        padding-left: 7%;
        padding-top: 3%;
    }
    .summary-title .hr-left {
        padding-left: 0;
    }
    .bottom-hr-summary {
        margin-top: 2rem;
    }
}

@media (max-width: 767px) {
    .modal-header {
        font-size: 1.2rem;
    }
    .modal-header .btn-close {
        right: 1%;
        top: 3%;
    }
    .preferred-title {
        margin-bottom: 0;
    }
    .confirm-btn-title {
        justify-content: center;
        display: flex;
    }
    .confirm-btn-title button {
        margin-left: 0;
        margin-top: 2%;
    }
    .contact-title {
        margin-left: 25px;
    }
    .dealer-heading label {
        display: unset;
    }
    #googleMap > div {
        height: 535px !important;
    }
    .dealer-locations {
        max-height: 75%;
    }
}

@media (max-width: 575px) {
    .what-text {
        margin-top: 0;
        margin-left: 14%;
        margin-bottom: 5%;
    }
    .confirmation-heading {
        margin-bottom: 5%;
    }
/*    .confirm-button {
        width: 68%;
    }*/
    .footer-col {
        padding-left: 5%;
    }
    .help-title-row hr {
        margin: 0;
        margin-top: 25px;
    }
    .faq-row {
        margin-top: 6%;
        margin-bottom: 2%;
    }
    .tooltips .what-text .tooltiptext {
        left: 10%;
        top: 63%;
    }
    .checkout-summary {
        margin-bottom: 5%;
    }
    .keep-layout .col-sm-10 {
        width: 83.33333333%;
    }
/*    .keep-layout .col-sm-9 {
        width: 75%;
    }*/
    .keep-layout .col-sm-8 {
        width: 66.66666667%;
    }
    .keep-layout .col-sm-6 {
        width: 50%;
    }
    .keep-layout .col-sm-4 {
        width: 33.33333333%
    }
/*    .keep-layout .col-sm-3 {
        width: 25%;
    }*/
    .keep-layout .col-sm-2 {
        width: 16.66666667%
    }
    .keep-layout .col-xl-6 {
        width: 50%;
    }
    .review-heading-bottom {
        margin-top: 5%;
    }
    .payment-right {
        margin-left: 0;
    }
    .summary-img-col {
        width: 55%;
        margin: auto;
    }
    .item-holder p {
        text-align: center;
    }
    #ship-dealer .search-btn {
        margin-left: 3%;
    }
    .item-holder .dealer-info p {
        text-align: left;
    }
    .dealer-info {
        margin-left: 6%;
    }
    .keep-partial-layout .col-small-5 {
        width: 80%;
    }
    .keep-partial-layout .col-small-1 {
        width: 20%;
        margin-top: 5%;
    }
    .faq-heading p {
        text-align: right;
    }
    .faq-tabs-col {
        padding-right: 0.75rem;
    }
    .faq-description ol, .faq-description ul {
        margin-left: 5%;
    }
    .confirm-zip-btn {
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .confirm-zip-search .mb-3 {
        margin-bottom: 0.5rem !important;
    }
    .footer-bottom-text {
        margin-bottom: 5%;
    }
    .contact-title {
        margin-left: 0;
    }
    .contact-back {
        text-align: right;
    }
    .modal-footer .btn-tertiary {
        margin-bottom: 5%;
    }
    .payment-middle {
        border-top: 0;
    }
    .payment-row-bottom {
        border-top: 0;
    }
    .keep-layout-confirm .col-sm-9 {
        width: 75%;
    }
    .keep-layout-confirm .col-sm-3 {
        width: 25%;
    }
    #googleMap > div {
        height: 515px !important;
    }
    .dealer-locations {
        max-height: 100%;
    }
    .return-print {
        margin-top: 0;
    }
    .cancel-btn > div {
        display: flex;
        justify-content: center;
    }
    .cancel-btn {
        margin-bottom: 10%;
    }
    .help-under-title {
        margin-bottom: 10px;
    }
    .footer-phone br {
        display: none;
    }
}

@media (max-width: 455px) {
    .help-heading {
        white-space: nowrap;
    }
    .confirm-btn-title button {
        margin-left: 8%;
    }
    .address-modal-row {
        margin-left: 0;
    }
    .tooltips .tooltiptext {
        left: 29%;
    }
    .tooltips .what-text .tooltiptext {
        top: 73%;
    }
    .dealer-ship-box label {
        display: unset;
    }
    .dealer-ship-box .search-btn {
        height: 40px;
        width: 40px;
    }
}

@media (max-width: 410px) {
    .checkout-captcha {
        justify-content: center;
    }
    .captcha .checkout-captcha {
        transform-origin: unset;
    }
}

@media (max-width: 393px) {
    .confirm-title {
        font-size: 45px;
    }
    .help-title-row hr {
        margin-top: 45px;
    }
    .help-order-text-help {
        text-align: center;
        width: fit-content;
        margin-bottom: 5% !important;
    }
    .modal-header .h4 {
        width: 90%;
    }
    .contact-text {
        width: 100%;
    }
}

@media (max-width: 380px) {
    .g-recaptcha {
        transform: scale(0.8);
        transform-origin: 0 0
    }
}

@media (max-width: 330px) {
    .keep-layout .col-sm-4 {
        width: 100%;
    }
    .keep-layout-confirm .col-sm-9 {
        width: 100%;
    }
    .keep-layout-confirm .col-sm-3 {
        width: 100%;
        margin: 0 auto 3% auto;
    }
    .g-recaptcha {
        transform: scale(0.7);
        transform-origin: 0 0
    }
}

@media (max-width: 305px) {
    .return details .contact-title {
       white-space: unset;
       text-align: left;
    }
}

@media (max-width: 295px) {
    label {
        display: unset;
    }
    .footer-title p {
        text-align: center;
    }
    .amex {
        height: unset;
    }
    .g-recaptcha {
        transform: scale(0.6);
        transform-origin: 0 0
    }
}
